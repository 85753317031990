// Determine the API base URL based on the environment
export const API_BASE_URL = process.env.NODE_ENV === 'production'
  ? 'api'
  : `http://localhost:8000/api`;

// Define the endpoints
export const LOGIN_ENDPOINT = `${API_BASE_URL}/auth/email/`;
export const REFRESH_TOKEN_ENDPOINT = `${API_BASE_URL}/auth/refresh/`;
export const OTP_VERIFICATION_ENDPOINT = `${API_BASE_URL}/auth/email/verify-otp/`;

export const CUSTOMERS_ENDPOINT = `${API_BASE_URL}/customers/`;
export const CUSTOMERS_SEARCH_ENDPOINT = `${API_BASE_URL}/customers/search/`;
export const CUSTOMERS_CREATE_ENDPOINT = `${API_BASE_URL}/customers/add/`;
export const CUSTOMERS_EDIT_ENDPOINT = `${API_BASE_URL}/customers/edit/`;

export const VANS_ENDPOINT = `${API_BASE_URL}/drivers/vans/`;
export const VANS_EDIT_ENDPOINT = `${API_BASE_URL}/drivers/vans/edit/`;
export const VANS_CREATE_ENDPOINT = `${API_BASE_URL}/drivers/vans/add/`;

export const DRIVERS_ENDPOINT = `${API_BASE_URL}/drivers/`;
export const DRIVERS_SEARCH_ENDPOINT = `${API_BASE_URL}/drivers/search/`;
export const DRIVERS_CREATE_ENDPOINT = `${API_BASE_URL}/drivers/add/`;
export const DRIVERS_EDIT_ENDPOINT = `${API_BASE_URL}/drivers/edit/`;

export const USERS_ENDPOINT = `${API_BASE_URL}/users/`;
export const USERS_CREATE_ENDPOINT = `${API_BASE_URL}/users/`;
export const USERS_EDIT_ENDPOINT = `${API_BASE_URL}/users/`;

export const ZONES_ENDPOINT = `${API_BASE_URL}/zones/`;
export const ZONE_CREATE_ENDPOINT = `${API_BASE_URL}/zones/add/`;
export const ZONE_EDIT_ENDPOINT = `${API_BASE_URL}/zones/edit/`;
export const SUBZONES_ENDPOINT = `${API_BASE_URL}/zones/sub-zones/`;
export const SUBZONE_EDIT_ENDPOINT = `${API_BASE_URL}/zones/sub-zones/edit/`;
export const SUBZONE_CREATE_ENDPOINT = `${API_BASE_URL}/zones/sub-zones/add/`;
export const ORDERS_ENDPOINT = `${API_BASE_URL}/orders/`;
export const ORDERS_STATUS_EDIT_ENDPOINT = `${API_BASE_URL}/orders/edit-status/`;

export const DRIVER_ORDER_REPORTS_URL = `${API_BASE_URL}/reports/drivers/`;
export const CUSTOMER_ORDER_REPORTS_URL = `${API_BASE_URL}/reports/customers/`;
export const ZONE_ORDER_REPORTS_URL = `${API_BASE_URL}/reports/zones/`;

export const MONTHLY_PERFORMANCE_REPORTS_URL = `${API_BASE_URL}/reports/orders-monthly-aggregate/`;
export const TIMESLOT_REPORTS_URL = `${API_BASE_URL}/reports/orders-timeslot-aggregate/`;

export const JOBS_ENDPOINT = `${API_BASE_URL}/jobs/`;
export const JOB_EXECUTE_ENDPOINT = `${API_BASE_URL}/jobs/execute-job/`;
export const JOBS_LOG_ENDPOINT = `${API_BASE_URL}/jobs/logs/`;

export const TEMPLATE_ENDPOINT = `${API_BASE_URL}/templates/`;
export const TEMPLATE_CREATE_ENDPOINT = `${API_BASE_URL}/templates/create/`;
export const TEMPLATE_DELETE_ENDPOINT = `${API_BASE_URL}/templates/delete/`;

export const TEMPLATE_BROADCAST_ENDPOINT = `${API_BASE_URL}/templates/broadcast/`;

export const AUDIENCE_GET_ENDPOINT = `${API_BASE_URL}/audience/`;
export const AUDIENCE_UPDATE_ENDPOINT = `${API_BASE_URL}/audience/add-segment/`;

export const SEGMENT_CREATE_ENDPOINT = `${API_BASE_URL}/audience/segments/create/`;
export const SEGMENT_EDIT_ENDPOINT = `${API_BASE_URL}/audience/segments/update/`;

export const CAMPAIGN_LIST_ENDPOINT = `${API_BASE_URL}/campaign/`;

export const WHATSAPP_BILLING_LIST_ENDPOINT = `${API_BASE_URL}/app-usage-analytics/`;
export const WHATSAPP_BILLING_CREATE_ENDPOINT = `${API_BASE_URL}/app-usage-analytics/create/`;

export const WHATSAPP_META_CARD_ENDPOINT = `${API_BASE_URL}/app-usage-analytics/meta-rate-card/`;

export const WHATSAPP_MESSAGE_LOG_ENDPOINT = `${API_BASE_URL}/logs/whatsapp/messages/`;
export const WHATSAPP_BROADCAST_LOG_ENDPOINT = `${API_BASE_URL}/logs/whatsapp/broadcast-marketing/`;
export const WHATSAPP_MARKETING_LOG_ENDPOINT = `${API_BASE_URL}/logs/whatsapp/marketing/`;
export const REPORT_EXPORT_URL = `${API_BASE_URL}/reports/export-csv/`;

export const GENERATE_QR_ENDPOINT = `${API_BASE_URL}/url-qrcode/`;

export const SETTINGS_DASHBOARD_ENDPOINT = `${API_BASE_URL}/settings-dashboard/`;