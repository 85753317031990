import * as React from "react";
import SettingsDashboardForm from './SettingsDashboardForm';
import Container from '@mui/material/Container';

const SettingsDashboard = (props) => { 

  return (
    <div className="App">
      <Container maxWidth={false}>
        <h2 className="heading-title">Dashboard</h2>
        <SettingsDashboardForm 
            refreshkey={props.refreshKey} 
        />
      </Container>

    </div>
  );
}
export default SettingsDashboard;
