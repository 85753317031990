import * as React from "react";
import GenerateQRForm from './GenerateQRForm';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import WAChatWidget from './WAChatWidget';
import WAChatLink from './WAChatLink';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const GenerateQR = (props) => {
  const [selectedOption, setSelectedOption] = React.useState("generateQR");

  const options = [
    { id: "generateQR", label: "WhatsApp QR Code Generator" },
    { id: "chatLinkGenerator", label: "WhatsApp Chat Link Generator" },
    { id: "chatBtnGenerator", label: "WhatsApp Chat Button Generator" },
  ];

  const handleOptionClick = (optionId) => {
    setSelectedOption(optionId);
  };

  const renderContent = () => {
    switch (selectedOption) {
      case "generateQR":
        return <GenerateQRForm refreshKey={props.refreshKey} />;
      case "chatLinkGenerator":
        return <WAChatLink refreshKey={props.refreshKey} />;
      case "chatBtnGenerator":
        return <WAChatWidget refreshKey={props.refreshKey} />;
      default:
        return <div>Select an option from the sidebar.</div>;
    }
  };

  return (
    <div className="App">
      <Container maxWidth={false}>
        <Grid container spacing={2}>
          {/* Sidebar */}
          <Grid item xs={3}>
            <List
              sx={{
                width: '100%',
                maxWidth: 380,
                bgcolor: 'background.paper',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
              }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  sx={{
                    bgcolor: 'background.default',
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    mt: 4,
                  }}
                >
                  Actions
                </ListSubheader>
              }
            >
              {options.map((option, index) => (
                <React.Fragment key={option.id}>
                  <StyledListItem disablePadding>
                    <ListItemButton
                      selected={selectedOption === option.id}
                      onClick={() => handleOptionClick(option.id)}
                    >
                      <ListItemText primary={option.label} />
                    </ListItemButton>
                  </StyledListItem>
                  {index < options.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Grid>

          {/* Content Area */}
          <Grid item xs={9}>
            <h2 className="heading-title">
              {options.find((option) => option.id === selectedOption)?.label}
            </h2>
            {renderContent()}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default GenerateQR;
