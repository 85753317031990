import * as React from "react";
import MetaCardTable from './MetaCardTable';
import Container from '@mui/material/Container';

const MetaCard = (props) => { 

  return (
    <div className="App">
      <Container maxWidth={false}>
        <h2 className="heading-title">Meta Rate Card</h2>
        <MetaCardTable 
            refreshkey={props.refreshKey} 
        />
      </Container>

    </div>
  );
}
export default MetaCard;
