import * as React from "react";
 
// importing material UI components
import { useState } from 'react';
import MarketingLogsTable from './MarketingLogsTable';
import Container from '@mui/material/Container';

const MarketingLogs = (props) => { 
  return (
    <div className="App">
      <Container maxWidth={false}>
        <h2 className="heading-title">Marketing Templates Logs</h2>
        <MarketingLogsTable key={props.refreshKey} />
      </Container>
    </div>
  );
}
export default MarketingLogs;
