import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { Grid } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import CreateIcon from '@mui/icons-material/Create';

import AudienceFormDialog from './AudienceFormDialog';
import { AUDIENCE_GET_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const columns = [
  { id: 'first_name', label: 'First Name' },
  { id: 'last_name', label: 'Last Name' },
  { id: 'country_code', label: 'Country Code' },
  { id: 'phone_no', label: 'Phone No' },
  { id: 'opted_in', label: 'Opted In' },
  { id: 'segments', label: 'Segment' },
  { id: 'email', label: 'Email' },
  { id: 'gender', label: 'Gender' },
  { id: 'age', label: 'Age' },
  { id: 'dob', label: 'DOB' },
  { id: 'action', label: 'Action' },
];

export default function AudienceTable({ refreshKey }) {
  const { getValidToken } = useAuth();
  const [rowCount, setRowCount] = useState(0);
  const [audienceData, setAudienceData] = useState([]);
  const [audienceModalState, setAudienceModalState] = useState({ isOpen: false, selectedClient: null, mode: 'add', jsonData: '' });
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });
  const [allSegments, setAllSegments] = useState([]);
  const [activeSegments, setActiveSegments] = useState([]);
  const [allSegmentsDetails, setAllSegmentsDetails] = useState([]);
  const [allActiveSegmentsDetails, setAllActiveSegmentsDetails] = useState([]);

  const [allZonesDetails, setAllZonesDetails] = useState([]);

  const handleCreateSegmentButton = () => {
    setAudienceModalState({ isOpen: true, selectedClient: null, mode: 'create_segment', jsonData: '' });
  };

  const handleEditSegmentButton = (audience) => {
    setAudienceModalState({ isOpen: true, selectedClient: null, mode: 'edit_segment', jsonData: '' });
  }

  // initilized state variables to store data
  const [searchQuery, setSearchQuery] = useState('');

  const fetchData = async () => {
    let searchDateURL = '';

    // Construct query parameters for server-side filtering and pagination
    const queryParams = new URLSearchParams({
      limit: controller.rowsPerPage.toString(),
      offset: (controller.serverSidePage * controller.rowsPerPage).toString(),
      search_query: searchQuery
    });

    const token = await getValidToken();

    try {
      const response = await fetch(`${AUDIENCE_GET_ENDPOINT}?${queryParams}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const jsonData = await response.json();

      console.log("GET API response: ", jsonData);

      setAudienceData(jsonData.results);
      setRowCount(jsonData.count);

      setAllSegments(jsonData.all_segments_details.map(segment => segment.segment_name));
      setActiveSegments(jsonData.all_segments_details.filter(segment => segment.active === true).map(segment => segment.segment_name));
      setAllSegmentsDetails(jsonData.all_segments_details);
      setAllActiveSegmentsDetails(jsonData.all_segments_details.filter(segment => segment.active === true));

      setAllZonesDetails(jsonData.all_zones_details);
      
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, [
    controller.serverSidePage, 
    controller.rowsPerPage, 
    refreshKey,
    searchQuery
  ]);

  // pagination
  const handlePageChange = (event, newPage) => {
    setController(prev => ({
      ...prev,
      page: newPage,
      serverSidePage: newPage  // Sync server-side page with UI page
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...prev => ({
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        serverSidePage: 0  // Reset to first page
      })
    });
  };

  const handleEditClick = (audience) => {
    setAudienceModalState({ isOpen: true, selectedClient: audience, mode: 'update_customer_segments' });
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredAudience  = audienceData.filter((audience) => {

    const searchQueryMatch = audience.first_name.toLowerCase().includes(searchQuery.toLowerCase());

    return searchQueryMatch;
  });

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Button variant="contained" sx={{ float: 'right', marginRight: 2 }} onClick={handleEditSegmentButton}><CreateIcon /></Button>
        <Button variant="contained" sx={{ float: 'right', marginRight: 2 }} onClick={handleCreateSegmentButton} ><AddIcon /></Button>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                label="Search"
                value={searchQuery}
                onChange={handleSearchQueryChange}
              />
            </Grid>
          </Grid>

        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                  >
                    <h3>{column.label}</h3>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAudience.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No match found
                  </TableCell>
                </TableRow>
              ) : (
                filteredAudience.map((audience, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.first_name}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.last_name}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)', width: '6%' }}>{audience.country_code}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.phone_no}</TableCell>
                    <TableCell align="center" style={{ border: '1px solid rgba(224, 224, 224, 1)', width: '7%' }}>
                      {audience.opted_in === 1 ? 
                        <CheckCircleIcon sx={{ color: 'green' }} /> :
                        audience.opted_in === 0 ?
                          <CancelIcon sx={{ color: 'red' }} /> :
                          <CancelIcon sx={{ color: 'red' }} />
                      }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {audience.segments ? 
                        audience.segments.join(', ') :
                          "Unknown"
                      }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.email}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.gender}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.age}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{audience.dob}</TableCell>
                    <TableCell align="center" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      <Button variant="contained" onClick={() => handleEditClick(audience)}>EDIT</Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rowCount}
          rowsPerPage={controller.rowsPerPage}
          page={controller.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <AudienceFormDialog
          modalState={audienceModalState}
          handleModalState={setAudienceModalState}
          selectedClient={audienceModalState.selectedClient}
          allSegments={allSegments}
          activeSegments={activeSegments}
          allSegmentsDetails={allSegmentsDetails}
          allActiveSegmentsDetails={allActiveSegmentsDetails}
          allZonesDetails={allZonesDetails}
          fetchData={fetchData}
        />
      </Paper>
    </div>
  );
}
