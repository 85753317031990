import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
    TextField,
    Button,
    Typography,
    IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const UploadCSV = ({
        campaignName, setCampaignName,
        campaignDescription, setCampaignDescription,
        csvFile, setCsvFile,
        campaignsData
    }) => {
    const [error, setError] = useState(null);
    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file.name.endsWith('.csv')) {
            setError('Please upload a CSV file');
            setCsvFile(null);
        } else {
            setError(null);
            setCsvFile(file);
        }
    };

    const handleDeleteHeaderFile = () => {
        setCsvFile(null);
    };

    const handleNameChange = (event) => {
        const newName = event.target.value;
        
        if (newName === '' || newName.trim().length > 0) {
            if (newName.length <= 50) {
                setCampaignName(newName);
            }
        }
    };
    
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        
        if (newDescription === '' || newDescription.trim().length > 0) {
            if (newDescription.length <= 250) {
                setCampaignDescription(newDescription);
            }
        }
    };

    useEffect(() => {
        const isDuplicate = campaignsData.some(campaign => campaign.toLowerCase() === campaignName.toLowerCase());
        setError(isDuplicate);
    }, [csvFile, campaignName, campaignDescription]);
    
    return (
        <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>

            {/* Campaign Name */}
            <Grid item xs={12}>
                <div style={{ position: 'relative', width: '90%', marginTop: 2, marginBottom: 2 }}>
                    <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                        Campaign Name *
                    </Typography>
                    <TextField
                        label="Campaign Name"
                        value={campaignName}
                        onChange={handleNameChange}
                        fullWidth
                        error={error}
                        helperText={error ? "Campaign name must be unique." : ""}
                        required
                    />
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'gray', 
                            position: 'absolute', 
                            right: '10px', 
                            top: '10px',
                            pointerEvents: 'none'
                        }}>
                        {`${campaignName.length}/50`}
                    </Typography>
                </div>
            </Grid>

            {/* Campaign Description */}
            <Grid item xs={12}>
                <div style={{ position: 'relative', width: '90%', marginTop: 2, marginBottom: 2 }}>
                    <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                        Campaign Description
                    </Typography>
                    <TextField
                        label="Campaign Description"
                        multiline
                        rows={4}
                        value={campaignDescription}
                        onChange={handleDescriptionChange}
                        fullWidth
                    />
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'gray', 
                            position: 'absolute', 
                            right: '10px', 
                            top: '10px',
                            pointerEvents: 'none'
                        }}>
                        {`${campaignDescription.length}/250`}
                    </Typography>
                </div>
            </Grid>
            
            {/* Upload CSV */}
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                    Upload CSV *
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <label htmlFor="csv-file-upload">
                    <Button
                        variant="contained"
                        component="span"
                        sx={{ marginBottom: 1 }}
                    >
                        Choose .CSV file
                    </Button>
                </label>
                <input
                    id="csv-file-upload"
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    required
                />
                {error && <Typography color="error">{error}</Typography>}
                {csvFile && (
                    <div>
                        <Typography variant="body2">
                            File uploaded: {csvFile.name ?? ''}
                        </Typography>
                        <IconButton
                            aria-label="delete"
                            onClick={handleDeleteHeaderFile}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </div>
                )}
            </Grid>
        </Grid>
    );
};
export default UploadCSV;