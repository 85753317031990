import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Material UI Icons
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ForumIcon from '@mui/icons-material/Forum';
import ChatIcon from '@mui/icons-material/Chat';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupsIcon from '@mui/icons-material/Groups';
import BarChartIcon from '@mui/icons-material/BarChart';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ErrorIcon from '@mui/icons-material/Error';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import QrCodeIcon from '@mui/icons-material/QrCode';
import DashboardIcon from '@mui/icons-material/Dashboard';

import BlankTab from './BlankTab';
import CustomersTab from './customers/CustomersTab';
import DriversTab from './drivers/DriversTab';
import OrdersTab from './orders/OrdersTab';
import DriverOrderReportTab from './reports/drivers/DriverOrderReportTab';
import ZoneOrderReportTab from './reports/zones/ZoneOrderReportTab';
import CustomerOrderReportTab from './reports/customers/CustomerOrderReportTab';
import MonthlyPerformanceReportTab from './reports/graphs/MonthlyPerformanceReportTab';
import TimeSlotWiseReportTab from './reports/graphs/TimeSlotWiseReportTab';

import UsersTab from './users/UsersTab';
import JobsTab from './jobs/JobsTab';
import ZonesTab from './zones/ZonesTab';
import ZoneAssignmentsTab from './zones/ZonesAssignmentTab';
import ChatDashboard from './ChatDashboard';
import Whatsapp from './Whatsapp/Whatsapp';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../hooks/AuthProvider';
import WhatsAppMessage from './WhatsAppMessage';
import MarketingLogsTable from './MarketingLogs/MarketingLogs';
import ErrorLogsTable from './ErrorLogs';
import Broadcast from './BroadcastTemplates/Broadcast';
import Audience from './Audience/Audience';
import Campaigns from './Campaigns/Campaigns';
import AppUsage from './AppUsage/AppUsage';
import MetaCard from './MetaCard/MetaCard';
import GenerateQR from './GenerateQR/GenerateQR';
import SettingsDashboard from './SettingsDashboard/SettingsDashboard';

const drawerWidth = 270;


const sidebarData = {
     "1": { title: "Customers", component: (props) => <CustomersTab {...props} />, icon: <GroupIcon /> },
     "2": { title: "Drivers", component: (props) => <DriversTab {...props} />, icon: <LocalShippingIcon /> },
     "3": { title: "Order Requests", component: (props) => <OrdersTab {...props} />, icon: <Inventory2RoundedIcon /> },
     "4": { title: "Zones", component: (props) => <ZonesTab {...props} />, icon: <AddLocationIcon /> },
     "5": { title: "Zone Assignment", component: (props) => <ZoneAssignmentsTab {...props} />, icon: <EditLocationAltIcon /> },
     "6": { title: "Order Requests Per Drivers", component: (props) => <DriverOrderReportTab {...props} />, icon: <PersonIcon /> },
     "7": { title: "Order Requests Per Zone", component: (props) => <ZoneOrderReportTab {...props} />, icon: <LocationOnRoundedIcon /> },
     "8": { title: "Order Requests Per Customers", component: (props) => <CustomerOrderReportTab {...props} />, icon: <GroupIcon /> },
     "9": { title: "Order Requests Per Month", component: (props) => <MonthlyPerformanceReportTab {...props} />, icon: <CalendarMonthIcon /> },
     "10": { title: "Order Requests Per Timeslot", component: (props) => <TimeSlotWiseReportTab {...props} />, icon: <AccessTimeIcon /> },
     "11": { title: "Users", component: (props) => <UsersTab {...props} />, icon: <PersonIcon /> },
     "12": { title: "Cron", component: (props) => <JobsTab {...props} />, icon: <TaskAltIcon /> },
     "13": { title: "Chat", component: (props) => <ChatDashboard {...props} />, icon: <ChatIcon /> },
     "14": { title: "WhatsApp Templates", component: (props) => <Whatsapp {...props} />, icon: <WhatsAppIcon /> },
     "15": { title: "WhatsApp Messages", component: (props) => <WhatsAppMessage {...props} />, icon: <ForumIcon /> },
     "16": { title: "Broadcast Templates", component: (props) => <Broadcast {...props} />, icon: <CampaignIcon /> },
     "17": { title: "Audience", component: (props) => <Audience {...props} />, icon: <GroupsIcon /> },
     "18": { title: "Campaigns", component: (props) => <Campaigns {...props} />, icon: <CampaignIcon /> },
     "19": { title: "WhatsApp Billing", component: (props) => <AppUsage {...props} />, icon: <BarChartIcon /> },
     "20": { title: "WhatsApp Messages Logs", component: (props) => <WhatsAppMessage {...props} />, icon: <SmartToyIcon /> },
     "21": { title: "Marketing Template Logs", component: (props) => <MarketingLogsTable {...props} />, icon: <ForumIcon /> },
     "22": { title: "Error Logs", component: (props) => <ErrorLogsTable {...props} />, icon: <ErrorIcon   /> },
     "23": { title: "Meta Rate Card", component: (props) => <MetaCard {...props} />, icon: <AttachMoneyIcon /> },
     "24": { title: "Tools", component: (props) => <GenerateQR {...props} />, icon: <QrCodeIcon /> },
     "25": { title: "Dashboard", component: (props) => <SettingsDashboard {...props} />, icon: <DashboardIcon /> },
};
                                                                                            

export default function MiniDrawer(props) {
  const [selectedComponentKey, setSelectedComponentKey] = useState("1"); // Keep track of the selected component key

  const handleListItemClick = (key) => {
    setSelectedComponentKey(key);
  }

  // dynamically generate the component with props
  const getComponentWithProps = (key) => {
    const Component = sidebarData[key].component;
    
    if (key === "16") {
      return (props) => <Component {...props} onNavigate={handleListItemClick} />;
    }
    
    // For other components
    return Component;
  }

  const SelectedComponent = getComponentWithProps(selectedComponentKey);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <CssBaseline />
      <Header />

      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar sx={{ my: 1 }} />
          <Box sx={{ overflow: 'auto' }}>
            <List subheader={<ListSubheader component="div">Main</ListSubheader>}>
              {['1', '2', '3', '4', '5', '12'].map((key) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: "5px 20px", // Compact padding
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: 32, // Adjust icon space
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick(key)}>
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })} {/* Small icon */}
                    </ListItemIcon>
                    <ListItemText
                      primary={sidebarData[key].title}
                      primaryTypographyProps={{
                        fontSize: "small", // Smaller text
                        fontWeight: 500,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List subheader={<ListSubheader component="div">Reports</ListSubheader>}>
              {['6', '7', '8', '9', '10'].map((key) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: "5px 20px", // Compact padding
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: 32, // Adjust icon space
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick(key)}>
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })}
                    </ListItemIcon>
                    <ListItemText
                      primary={sidebarData[key].title}
                      primaryTypographyProps={{
                        fontSize: "small",
                        fontWeight: 500,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List subheader={<ListSubheader component="div">WhatsApp Marketing</ListSubheader>}>
              {['14', '16', '17', '18'].map((key) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: "5px 20px", // Compact padding
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: 32, // Adjust icon space
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick(key)}>
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })}
                    </ListItemIcon>
                    <ListItemText
                      primary={sidebarData[key].title}
                      primaryTypographyProps={{
                        fontSize: "small",
                        fontWeight: 500,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List subheader={<ListSubheader component="div">Logs</ListSubheader>}>
              {['20', '21'].map((key) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: "5px 20px", // Compact padding
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: 32, // Adjust icon space
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick(key)}>
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })}
                    </ListItemIcon>
                    <ListItemText
                      primary={sidebarData[key].title}
                      primaryTypographyProps={{
                        fontSize: "small",
                        fontWeight: 500,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider/>
            <List subheader={<ListSubheader component="div">WhatsApp Chatbot</ListSubheader>}>
              {['13'].map((key) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: "5px 20px", // Compact padding
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: 32, // Adjust icon space
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick(key)}>
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })}
                    </ListItemIcon>
                    <ListItemText
                      primary={sidebarData[key].title}
                      primaryTypographyProps={{
                        fontSize: "small",
                        fontWeight: 500,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List subheader={<ListSubheader component="div">Billing</ListSubheader>}>
              {['19', '23'].map((key) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: "5px 20px", // Compact padding
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: 32, // Adjust icon space
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick(key)}>
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })}
                    </ListItemIcon>
                    <ListItemText
                      primary={sidebarData[key].title}
                      primaryTypographyProps={{
                        fontSize: "small",
                        fontWeight: 500,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
            <List subheader={<ListSubheader component="div">Settings</ListSubheader>}>
              {['25', '11', '24'].map((key) => (
                <ListItem
                  key={key}
                  disablePadding
                  sx={{
                    "& .MuiListItemButton-root": {
                      padding: "5px 20px", // Compact padding
                    },
                    "& .MuiListItemIcon-root": {
                      minWidth: 32, // Adjust icon space
                    },
                  }}
                >
                  <ListItemButton onClick={() => handleListItemClick(key)}>
                    <ListItemIcon>
                      {React.cloneElement(sidebarData[key].icon, { fontSize: "small" })}
                    </ListItemIcon>
                    <ListItemText
                      primary={sidebarData[key].title}
                      primaryTypographyProps={{
                        fontSize: "small",
                        fontWeight: 500,
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>

        </Drawer>

        <Box component="main" sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
          <Toolbar sx={{ my: 0 }} />
          <Box sx={{ flexGrow: 1, mb: 1, mt: 3 }}>
            {SelectedComponent && <SelectedComponent />}
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
}
