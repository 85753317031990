import React, { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Card, CardContent } from '@mui/material';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.mjs',
	import.meta.url,
).toString();

const PDFPreview = ({ pdfFile }) => {
  const [error, setError] = useState(null);
  // Track page dimensions
  const [pageHeight, setPageHeight] = useState(null);

  const onLoadError = () => {
    setError('Error loading PDF');
  };

  // Callback to get page dimensions and set height
  const onLoadSuccess = ({ height }) => {
    setPageHeight(height);
  };

  useEffect(() => {
    console.log('Page height:', pageHeight);
  }, [pageHeight]);

  if (error) {
    return (
      <Card className="w-[220px] h-[280px] rounded-t-lg">
        <CardContent className="flex items-center justify-center h-full">
          <div className="text-red-500">{error}</div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-[220px] h-[280px] rounded-t-lg overflow-hidden">
      <CardContent className="p-0 h-[280px] overflow-hidden">
        <Document
          file={pdfFile}
          onLoadSuccess={onLoadSuccess}
          className="h-[280px] w-full"
          onLoadError={onLoadError}
          loading={
            <div className="flex items-center justify-center h-[280px] bg-gray-50">
              <div className="text-gray-500">Loading PDF...</div>
            </div>
          }
          error={
            <div className="flex items-center justify-center h-[280px] bg-gray-50">
              <p className="text-gray-500 text-center">
                PDF preview not available.<br />Please download to view.
              </p>
            </div>
          }
        >
          <div
            className="h-[280px] overflow-hidden"
            style={{
              // clipPath: 'inset(0 0 50% 0)', // Clipping lower half of the content
              height: '70px', // Limit the visible height
              overflow: 'hidden',
            }}
          >
            <Page
              pageNumber={1}
              height={280}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          </div>
        </Document>
      </CardContent>
    </Card>
  );
};

export default PDFPreview;