import React, { useState } from 'react';
import { useEffect } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
    Paper,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import { useAuth } from '../../hooks/AuthProvider';

import { SETTINGS_DASHBOARD_ENDPOINT } from '../../config/urls';


const SettingsDashboardForm = () => {
    const { getValidToken } = useAuth();

    const [data, setData] = useState({
        user_name: '',
        user_email: '',
        business_name: '',
        business_wa_number: ''
    });

    const fetchData = async () => {
        const token = await getValidToken();
        const response = await fetch(SETTINGS_DASHBOARD_ENDPOINT, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const responseData = await response.json();
        console.log("API Response:", responseData);
        
        setData(responseData);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const DetailField = ({ label, value, icon }) => (
        <Box sx={{ mb: 2 }}>
            <Typography variant="body1" sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                color: 'text.secondary',
                mb: 1 
            }}>
                {icon}
                <span style={{ marginLeft: '8px' }}>{label}</span>
            </Typography>
            <Paper
                elevation={0}
                sx={{
                    backgroundColor: '#f5f5f5',
                    p: 2,
                    borderRadius: 1
                }}
            >
                <Typography variant="body1">
                    {value}
                </Typography>
            </Paper>
        </Box>
    );

    return (
        <Card sx={{ maxWidth: 1200, margin: 'auto', mt: 2 }}>
            <CardContent>
                <Grid container spacing={3}>
                    {/* Agent Details Section */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h6" sx={{ mb: 3 }}>
                                Agent Details
                            </Typography>
                            <DetailField 
                                label="User Name:"
                                value={data.user_name}
                                icon={<PersonIcon sx={{ fontSize: 20 }} />}
                            />
                            <DetailField 
                                label="User Email:"
                                value={data.user_email}
                                icon={<EmailIcon sx={{ fontSize: 20 }} />}
                            />
                        </Box>
                    </Grid>

                    {/* WhatsApp Business Details Section */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ p: 2 }}>
                            <Typography variant="h6" sx={{ mb: 3 }}>
                                WhatsApp Business Details
                            </Typography>
                            <DetailField 
                                label="Business Name:"
                                value={data.business_name}
                                icon={<BusinessCenterIcon sx={{ fontSize: 20 }} />}
                            />
                            <DetailField 
                                label="Business WhatsApp Number:"
                                value={data.business_wa_number}
                                icon={<WhatsAppIcon sx={{ fontSize: 20 }} />}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SettingsDashboardForm;
