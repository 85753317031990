import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { ORDERS_ENDPOINT, ORDERS_STATUS_EDIT_ENDPOINT } from '../../config/urls';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAuth } from "../../hooks/AuthProvider";
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton'; // Import IconButton
import EditIcon from '@mui/icons-material/Edit'; // Import EditIcon
import InfoIcon from '@mui/icons-material/Info';
import Select from '@mui/material/Select'; // Import Select for dropdown
import MenuItem from '@mui/material/MenuItem'; // Import MenuItem for Select options
import FormControl from '@mui/material/FormControl'; // Import FormControl for Select
import InputLabel from '@mui/material/InputLabel'; // Import InputLabel for Select
import Tooltip from '@mui/material/Tooltip';

const columns = [
  { id: 'code', label: 'Order Code' },
  { id: 'customer_code', label: 'Customer Code' },
  { id: 'customer_name', label: 'Customer Name' },
  { id: 'driver_name', label: 'Driver Name' },
  { id: 'zone_name', label: 'Zone' },
  { id: 'time_slot', label: 'Time Slot' },
  { id: 'status', label: 'Status' },
  { id: 'party_size', label: 'Party Size' },
  { id: 'created_at', label: 'Created At' },
  { id: 'delivery_date', label: 'Request Delivery Date' },
  { id: 'order_delivered_date', label: 'Order Completed Date' },
];

function OrdersTable({ refreshKey, searchInput }) {
  const { getValidToken } = useAuth();
  const auth = useAuth();
  const navigate = useNavigate();

  const [rowCount, setRowCount] = useState(0);
  const [ordersData, setOrdersData] = useState([]);
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchTriggered, setSearchTriggered] = useState(false); // Search-triggered state

  // Modal state for Address Details
  const [openModal, setOpenModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  // **New States for Status Editing**
  const [openStatusModal, setOpenStatusModal] = useState(false); // State to control Status Edit Modal
  const [currentOrder, setCurrentOrder] = useState(null); // The order being edited
  const [newStatus, setNewStatus] = useState(''); // The new status selected by the user
  const [availableStatusOptions, setAvailableStatusOptions] = useState([]);
  // Function to fetch orders based on filter or without filter
  const fetchOrders = async (searchURL = '', isTriggered = false) => {
    const token = await getValidToken();
    let offset = controller.page * controller.rowsPerPage;

    try {
      const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      };

      if (isTriggered){
        offset = 0;
      }

      const response = await fetch(
        `${ORDERS_ENDPOINT}?limit=${controller.rowsPerPage}&offset=${offset}${searchURL}`,
        { headers }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.code === "user_inactive") {
          auth.logOut();
          navigate('/login');
        }
        throw new Error('Failed to fetch data');
      }

      const jsonData = await response.json();
      setOrdersData(jsonData.results);
      setRowCount(jsonData.count);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  // Call API initially without filters (default load) and when pagination or refreshKey changes
  useEffect(() => {
    // Only fetch without filters if not in search mode
    if (!searchTriggered && !searchInput) {
      fetchOrders(); // No filters on initial load
    }
  }, [controller, refreshKey]);

  // Call API with filters when search is triggered
  useEffect(() => {
    if (searchTriggered || searchInput) {
      let constructedSearchURL = '';

      if (searchInput) {
        constructedSearchURL += `&search_id=${encodeURIComponent(searchInput)}`;
      }
      if (startDate) {
        constructedSearchURL += `&start_date=${dayjs(startDate).format('YYYY-MM-DD')}`;
      }
      if (endDate) {
        constructedSearchURL += `&end_date=${dayjs(endDate).format('YYYY-MM-DD')}`;
      }

      setController((prev) => ({
        ...prev,
        page: 0
      }));

      console.log('Fetching with filters:', constructedSearchURL); // Debug log
      fetchOrders(constructedSearchURL, true); // Fetch with filters
      // setSearchTriggered(false); // Reset search trigger
    }
  }, [searchTriggered, searchInput, startDate, endDate]);

  // Handle pagination
  const handlePageChange = (event, newPage) => {
    setController((prev) => ({
      ...prev,
      page: newPage
    }));
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setController((prev) => ({
      ...prev,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    }));
  };

  // Trigger search when button is clicked
  const handleSearch = () => {
    setSearchTriggered(true);
    setController((prev) => ({
      ...prev,
      page: 0 // Reset to first page after search
    }));
  };

  // Reset date filters and search input
  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setController((prev) => ({
      ...prev,
      page: 0
    }));
    setSearchTriggered(true); // Trigger fetch without filters
  };

  // Modal handlers for Address Details
  const handleOpenModal = (order) => {
    setSelectedAddress({
      name: order.address.name,
      latitude: order.address.latitude,
      longitude: order.address.longitude,
      zone: order.address.zone,
      subzone: order.address.subzone, // Adjust according to your data structure
      full_address: order.address.full_address,
      appartment_details: order.address.appartment_details, 
      building_details: order.address.building_details,
      street_details: order.address.street_details,
      landmark_details: order.address.landmark_details
    });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedAddress(null);
  };

  // **New Modal Handlers for Status Editing**
  const handleOpenStatusModal = (order) => {
    setCurrentOrder(order);
    setNewStatus(order.status); // Set newStatus to the current status of the order
    setOpenStatusModal(true);
    
    // Set available options based on the current status
    let availableOptions = [];
    if (order.status === 'pending') {
        availableOptions = ['confirm', 'completed', 'cancelled'];
    } else if (order.status === 'confirm') {
        availableOptions = ['completed', 'cancelled'];
    } else {
        availableOptions = []; // No options for completed or cancelled
    }
    setAvailableStatusOptions(availableOptions);
};

  const handleCloseStatusModal = () => {
    setOpenStatusModal(false);
    setCurrentOrder(null);
    setNewStatus('');
  };

  // Function to handle status update
  const handleSaveStatus = async () => {
    if (!currentOrder) return;

    const token = await getValidToken();
    const updatedStatus = newStatus;

    try {
      const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
      };
      
      // Assuming the API expects a PATCH request to update the status
      const response = await fetch(`${ORDERS_STATUS_EDIT_ENDPOINT}${currentOrder.code}/`, {
        method: 'PATCH',
        headers,
        body: JSON.stringify({
          status: updatedStatus,
          // Optionally include updatedBy and updatedDate if required by the backend
          // updatedBy: auth.user.id, // Assuming you have user info
          // updatedDate: dayjs().toISOString(),
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update status');
      }

      // Update was successful, update the local state
      setOrdersData((prevData) =>
        prevData.map((order) =>
          order.code === currentOrder.code
            ? { ...order, status: updatedStatus }
            : order
        )
      );

      handleCloseStatusModal(); // Close the modal
    } catch (error) {
      console.error('Error updating status:', error.message);
      // Optionally, display an error message to the user
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          m: 1,
          bgcolor: 'background.paper',
          borderRadius: 1,
          alignItems: 'center',
          gap: 2,
        }}
      >
        {/* Date Filters */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DatePicker
              label="Start Date"
              value={startDate}
              format="DD/MM/YYYY" // Correct prop
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              label="End Date"
              value={endDate}
              format="DD/MM/YYYY" // Correct prop
              onChange={(date) => setEndDate(date)}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          <Button variant="contained" size="large" onClick={handleSearch}>
            Search
          </Button>
          <Button variant="outlined" size="large" onClick={handleReset}>
            Reset
          </Button>
        </LocalizationProvider>
        <Tooltip title="This filter applies to order requests created within the selected start and end dates." arrow>
              <IconButton
                  aria-label="edit status"
                  // onClick={() => alert("hello")}
                  // sx={{ ml: 1 }}
              >
                <InfoIcon fontSize="medium" />
              </IconButton>
          </Tooltip>
      </Box>

      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth || 100, border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No orders found
                </TableCell>
              </TableRow>
            ) : (
              ordersData.map((order) => (
                <TableRow key={order.code}> {/* Use a unique identifier */}
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{order.code}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{order.customer_code}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{order.customer_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{order.driver_name}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={() => handleOpenModal(order)}
                      sx={{ textTransform: 'none', padding: 0 }}
                    >
                      {order.zone_name}
                    </Button>
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{order.time_slot}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)', display: 'flex', alignItems: 'center' }}>
                  {order.status === 'completed' ? 
                      <Chip variant="outlined" color="success" label="Completed" size="small" /> :
                      order.status === 'confirm' ?
                        <Chip variant="outlined" color="primary" label="Confirmed" size="small" /> :
                      order.status === 'incomplete' ?
                        <Chip variant="outlined" color="default" label="Incomplete" size="small" /> :
                      order.status === 'cancelled' ?
                        <Chip variant="outlined" color="error" label="Cancelled" size="small" /> :
                      order.status === 'reject' ?
                        <Chip variant="outlined" color="error" label="Rejected" size="small" /> :
                        <Chip variant="outlined" color="warning" label="Pending" size="small" />
                    }

                    {/* Add Edit Icon */}
                    { order.status === 'pending' || order.status === 'confirm' ? 
                      (<IconButton
                          aria-label="edit status"
                          size="small"
                          onClick={() => handleOpenStatusModal(order)}
                          sx={{ ml: 1 }}
                      >
                          <EditIcon fontSize="small" />
                      </IconButton>) : null }
                    
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{order.party_size}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {order.created_at ? dayjs(order.created_at).format("DD/MM/YYYY") : "--"}
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {order.delivery_date ? dayjs(order.delivery_date).format("DD/MM/YYYY") : "--"}
                  </TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {order.order_delivered_date ? dayjs(order.order_delivered_date).format("DD/MM/YYYY") : "--"}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* Address Details Modal */}
      <Dialog open={openModal} onClose={handleCloseModal} maxWidth="sm" fullWidth>
        <DialogTitle>Address Details</DialogTitle>
        <DialogContent dividers>
          {selectedAddress ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Typography variant="body1"><strong>Address Name:</strong> {selectedAddress.name || '--'}</Typography>
              <Typography variant="body1"><strong>Latitude:</strong> {selectedAddress.latitude || '--'}</Typography>
              <Typography variant="body1"><strong>Longitude:</strong> {selectedAddress.longitude || '--'}</Typography>
              <Typography variant="body1"><strong>Zone:</strong> {selectedAddress.zone?.name || '--'}</Typography>
              <Typography variant="body1"><strong>Subzone:</strong> {selectedAddress.subzone?.name || '--'}</Typography>
              <Typography variant="body1"><strong>Appartment Details:</strong> {selectedAddress.appartment_details || '--'}</Typography>
              <Typography variant="body1"><strong>Buiding Details:</strong> {selectedAddress.building_details || '--'}</Typography>
              <Typography variant="body1"><strong>Street Details:</strong> {selectedAddress.street_details || '--'}</Typography>
              <Typography variant="body1"><strong>Landmark Details:</strong> {selectedAddress.landmark_details || '--'}</Typography>
              <Typography variant="body1"><strong>Full Address:</strong> {selectedAddress.full_address || '--'}</Typography>
            </Box>
          ) : (
            <Typography variant="body1">No address details available.</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>


      {/* **New Status Edit Modal** */}
<Dialog open={openStatusModal} onClose={handleCloseStatusModal} maxWidth="xs" fullWidth>
    <DialogTitle>Edit Order Status</DialogTitle>
    <DialogContent dividers>
        {currentOrder ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}>
                <Typography variant="body1">
                    <strong>Order Code:</strong> {currentOrder.code}
                </Typography>
                <Typography variant="body1">
                        <strong>Status:</strong> {currentOrder.status.charAt(0).toUpperCase() + currentOrder.status.slice(1)}
                    </Typography>
                    <Typography variant="body1">
                        <strong>Status Change To:</strong> 
                    </Typography>
                {currentOrder.status === 'completed' || currentOrder.status === 'cancelled' ? (
                    <Typography variant="body1">
                        <strong>Status:</strong> {currentOrder.status.charAt(0).toUpperCase() + currentOrder.status.slice(1)}
                    </Typography>
                ) : (
                    <FormControl fullWidth>
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                            labelId="status-select-label"
                            value={newStatus}
                            label="Status"
                            onChange={(e) => setNewStatus(e.target.value)}
                        >
                            {availableStatusOptions.map(option => (
                                <MenuItem key={option} value={option}>
                                    {option.charAt(0).toUpperCase() + option.slice(1)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </Box>
        ) : (
            <Typography variant="body1">No order selected.</Typography>
        )}
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCloseStatusModal}>
            Cancel
        </Button>
        {currentOrder && currentOrder.status !== 'completed' && currentOrder.status !== 'cancelled' && (
            <Button onClick={handleSaveStatus} variant="contained" color="primary">
                Save
            </Button>
        )}
    </DialogActions>
</Dialog>
    </Paper>
  );
}

export default OrdersTable;
