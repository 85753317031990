import React, { useState } from 'react';
import {
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Box,
  Alert,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { CopyAll } from '@mui/icons-material';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vs } from 'react-syntax-highlighter/dist/esm/styles/prism';

const WAChatLink = () => {
  const [phoneNumber, setPhoneNumber] = useState('9999999999');
  const [countryCode, setCountryCode] = useState('+91');
  const [message, setMessage] = useState('Sample Text');
  const [cardTitle, setCardTitle] = useState('');
  const [iconPlacement, setIconPlacement] = useState('bottom-right');
  const [buttonBgColor, setButtonBgColor] = useState('#25d366');
  const [generatedScript, setGeneratedScript] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  const handleGenerateScript = () => {
    if (phoneNumber && message) {
      const script = `
        https://api.whatsapp.com/send/?phone=${countryCode}${phoneNumber}&text=${encodeURIComponent(message)}

        
      `;

      setGeneratedScript(script.trim());
      setShowAlert(true);
    } else {
      window.alert('Please fill in all fields.');
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(generatedScript).then(() => {
      window.alert('Script copied to clipboard!');
    });
  };

  return (
    <Card sx={{ maxWidth: 1100, margin: 'auto', mt: 2, p: 2 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Generate WhatsApp Widget Script
        </Typography>
        <Box sx={{ mb: 2 }}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Country Code</InputLabel>
            <Select
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              label="Country Code"
            >
              <MenuItem value="+1">+1 (USA)</MenuItem>
              <MenuItem value="+44">+44 (UK)</MenuItem>
              <MenuItem value="+91">+91 (India)</MenuItem>
              <MenuItem value="+971">+971 (UAE)</MenuItem>
              <MenuItem value="+61">+61 (Australia)</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            label="Phone Number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Welcome Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Enter default message"
            sx={{ mb: 2 }}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateScript}
        >
          Generate Script
        </Button>
        {showAlert && (
          <Alert severity="success" onClose={() => setShowAlert(false)} sx={{ mt: 2 }}>
            Script generated successfully! Copy and paste it into your project.
          </Alert>
        )}
        {generatedScript && (
          <Box sx={{ mt: 2, position: 'relative' }}>
            <SyntaxHighlighter
              language="html"
              style={vs}
              customStyle={{
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: '#f5f5f5',
              }}
            >
              {generatedScript}
            </SyntaxHighlighter>
            <IconButton
              onClick={handleCopyToClipboard}
              sx={{
                position: 'absolute',
                top: 5,
                right: 5,
                color: 'white',
                backgroundColor: '#25d366',
                '&:hover': { backgroundColor: '#1da851' },
              }}
            >
              <CopyAll />
            </IconButton>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default WAChatLink;
