import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { Grid } from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
import { WHATSAPP_BROADCAST_LOG_ENDPOINT, WHATSAPP_MARKETING_LOG_ENDPOINT } from '../config/urls';

const columns = [
  { id: 'Date', label: 'DateTime' },
  { id: 'Status', label: 'Status' },
  { id: 'Message Type', label: 'Message Type' },
  { id: 'Template', label: 'Template' },
  { id: 'Message', label: 'Message' },
];

export default function ErrorLogsTable({ refreshKey, dateInput }) {
  const [rowCount, setRowCount] = useState(0);
  const [messageData, setmessageData] = useState([]);
  const [userModalState, setUserModalState] = useState({ isOpen: false, selectedClient: null, mode: 'add' });
  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  useEffect(() => {
    let searchDateURL = '';
    if (dateInput) {
      let dateString = dayjs(dateInput).format("YYYY-MM-DD");
      searchDateURL = `&search_date=${dateString}`;
    }

    const fetchData = async () => {
      try {
        const response = await fetch(WHATSAPP_BROADCAST_LOG_ENDPOINT + `?limit=${controller.rowsPerPage}&offset=${controller.page * controller.rowsPerPage}` + searchDateURL);
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const jsonData = await response.json();
        setmessageData(jsonData.results);
        setRowCount(jsonData.count);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
    fetchData();
  }, [controller, refreshKey, dateInput]);

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0
    });
  };

  const handleEditClick = (user) => {
    setUserModalState({ isOpen: true, selectedClient: user, mode: 'edit' });
  };

  const handleAddClick = () => {
    setUserModalState({ isOpen: true, selectedClient: null, mode: 'add' });
  };

  const handleRefresh = () => {
    setController({
      page: 0,
      rowsPerPage: 20
    });
  };

  const [activeButton, setActiveButton] = useState("Message"); // Default active button

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    console.log(`${buttonName} clicked`);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >   
            
            <Grid item xs={12} sx={{my: 1}}>
            <Button variant="contained" sx={{mx:6 }} onClick={handleRefresh}>Sync</Button>
              <Button variant="contained" sx={{mx:1 }}>Filter</Button>
              <Button variant="outlined" sx={{mx:1 }}>Reset</Button>
            </Grid>
            {/* <Grid item xs={12}>
              <Button variant="contained" ><RefreshIcon /> Sync</Button>
            </Grid> */}
          <Grid container spacing={1}>
          <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  label="From Date"
                  // value={fromDate}
                  // onChange={handleFromDateChange}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                <DatePicker
                  label="To Date"
                  // value={toDate}
                  // onChange={handleToDateChange}
                  // renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Grid>
            
            
            <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel> 
              <Select
                // value={categoryFilter}
                // onChange={handleCategoryFilterChange}
                displayEmpty
                fullWidth
              >
                <MenuItem value="success">success</MenuItem>
                <MenuItem value="error">error</MenuItem>
                {/* {categoryOptions} */}
              </Select>
              </FormControl>
            </Grid>
           
           
          </Grid>

        </Box>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  <h3>{column.label}</h3>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {messageData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  No match found
                </TableCell>
              </TableRow>
            ) : (
              messageData.map((message, index) => (
                <TableRow key={index}>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{dayjs(message.logged_at).format("MMMM D, YYYY, h:mm A")}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.status}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.message_type}</TableCell>
                  {/* <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    {message.is_active ? 
                      <Chip variant="outlined" color="success" label="active" size="small" /> : 
                      <Chip variant="outlined" color="error" label="inactive" size="small" />
                    }
                  </TableCell> */}
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message?.template}</TableCell>
                  <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{message.message}</TableCell>
                  
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        count={rowCount}
        rowsPerPage={controller.rowsPerPage}
        page={controller.page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
     
    </Paper>
  );
}
