import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  FormControl,
  Collapse,
  Typography,
  Alert,
  Box,
  TextField,
  Grid,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import { CUSTOMERS_CREATE_ENDPOINT, CUSTOMERS_EDIT_ENDPOINT } from '../../config/urls';
import dayjs from 'dayjs';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { useAuth } from '../../hooks/AuthProvider';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const languageOptions = [
  { code: 'en', label: 'English' },
  { code: 'ar', label: 'Arabic' },
];

const CustomerFormDialog = ({ modalState, handleModalState, selectedCustomer, refreshTable }) => {
  const { getValidToken } = useAuth();

  const [alertObj, setAlertObj] = useState({
    active: false,
    msg: "",
    type: "",
  });

  const [formData, setFormData] = useState({
    name: '',
    country_code: '',
    dial_code: '',
    phone_no: '',
    email: '',
    date_of_birth: null,
    language_code: 'en',
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    country_code: '',
    dial_code: '',
    phone_no: '',
    email: '',
    date_of_birth: '',
    language_code: '',
  });

  useEffect(() => {
    if (selectedCustomer) {
      console.log("selectedCustomer: ", selectedCustomer);
      setFormData({
        name: selectedCustomer.name,
        country_code: selectedCustomer.country_code,
        dial_code: selectedCustomer.dial_code,
        phone_no: selectedCustomer.phone_no,
        email: selectedCustomer.email || '',
        date_of_birth: selectedCustomer.date_of_birth ? dayjs(selectedCustomer.date_of_birth) : null,
        language_code: selectedCustomer.language_code || '',
      });
    } else {
      setFormData({
        name: '',
        country_code: '',
        dial_code: '',
        phone_no: '',
        email: '',
        date_of_birth: null,
        language_code: '',
      });
    }
  }, [selectedCustomer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

    const handleClose = () => {
    handleModalState({ isOpen: false });
    setAlertObj({ active: false, msg: '', type: '' });
    setFormErrors({
      name: '',
      country_code: '',
      dial_code: '',
      phone_no: '',
      email: '',
      date_of_birth: '',
    });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date_of_birth: date });
  };

  const validateForm = async () => {
    const newFormErrors = {};
    let hasErrors = false;
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (formData.name.trim() === '' || formData.name.length > 250) {
      newFormErrors.name = 'Please enter a name (max 250 characters)';
      hasErrors = true;
    }

    if (formData.country_code.trim() === '') {
      newFormErrors.country_code = 'Please enter a country code';
      hasErrors = true;
    }

    if (formData.dial_code.trim() === '') {
      newFormErrors.dial_code = 'Please enter a dial code';
      hasErrors = true;
    }

    if (formData.email && !emailPattern.test(formData.email.trim())) {
      newFormErrors.email = 'Please enter a valid email';
      hasErrors = true;
    }

    if (!formData.language_code) {
      newFormErrors.language_code = 'Please select a language';
      hasErrors = true;
    }

    setFormErrors(newFormErrors);
    return !hasErrors;
  };

  const handleAdd = async () => {
    if (!(await validateForm())) {
      return;
    }

    const formattedData = {
      ...formData,
      date_of_birth: formData.date_of_birth ? dayjs(formData.date_of_birth).format('YYYY-MM-DD') : null,
    };

    const token = await getValidToken();

    axios.post(CUSTOMERS_CREATE_ENDPOINT, formattedData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setAlertObj({
          active: true,
          type: "success",
          msg: "Customer created successfully",
        });
        refreshTable(key => key + 1);
      })
      .catch(error => {
        const errorResponse = error.response?.data || {};
        const newFormErrors = {
          name: errorResponse.name || '',
          country_code: errorResponse.country_code || '',
          dial_code: errorResponse.dial_code || '',
          phone_no: errorResponse.phone_no || '',
          email: errorResponse.email || '',
          date_of_birth: errorResponse.date_of_birth || '',
          language_code: errorResponse.language_code || '',
        };
        setFormErrors(newFormErrors);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to create customer",
        });
      });
  };

  const handleEdit = async () => {
    if (!(await validateForm())) {
      return;
    }

    const token = await getValidToken();

    const formattedData = {
      ...formData,
      date_of_birth: formData.date_of_birth ? dayjs(formData.date_of_birth).format('YYYY-MM-DD') : null,
    };

    axios.patch(`${CUSTOMERS_EDIT_ENDPOINT}${selectedCustomer.id}/`, formattedData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then(response => {
        setAlertObj({
          active: true,
          type: "success",
          msg: "Customer updated successfully",
        });
        refreshTable(key => key + 1);
      })
      .catch(error => {
        const errorResponse = error.response?.data || {};
        const newFormErrors = {
          name: errorResponse.name || '',
          country_code: errorResponse.country_code || '',
          dial_code: errorResponse.dial_code || '',
          phone_no: errorResponse.phone_no || '',
          email: errorResponse.email || '',
          date_of_birth: errorResponse.date_of_birth || '',
          language_code: errorResponse.language_code || '',
        };
        setFormErrors(newFormErrors);
        setAlertObj({
          active: true,
          type: "error",
          msg: "Failed to update customer",
        });
      });
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={modalState.isOpen}
      maxWidth="sm"
      fullWidth
    >
    <DialogTitle sx={{ m: 0, p: 2, position: 'relative' }} id="customized-dialog-title">
         {modalState.mode === 'edit' ? 'Edit Customer' : 'Create Customer'}
         <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 0,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Collapse in={alertObj.active}>
          <Alert severity={alertObj.type} onClose={() => setAlertObj({ active: false })}>
            {alertObj.msg}
          </Alert>
        </Collapse>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Name *"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!formErrors.name}
            helperText={formErrors.name}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="country-code-label">Country Code *</InputLabel>
          <Select
            label="Country Code *"
            labelId="country-code-label"
            name="country_code"
            value={formData.country_code}
            onChange={handleChange}
            error={!!formErrors.country_code}
            helperText={formErrors.country_code}
          >
            <MenuItem value="91">+91 (IN)</MenuItem>
            <MenuItem value="1">+1 (USA)</MenuItem>
            <MenuItem value="971">+971 (UAE)</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Dial Code *"
            name="dial_code"
            value={formData.dial_code}
            onChange={handleChange}
            error={!!formErrors.dial_code}
            helperText={formErrors.dial_code}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <TextField
            label="Email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!formErrors.email}
            helperText={formErrors.email}
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel id="language-code-label">Language *</InputLabel>
          <Select
            labelId="language-code-label"
            name="language_code"
            value={formData.language_code}
            onChange={handleChange}
            error={!!formErrors.language_code}
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="caption" color="error">
            {formErrors.language_code}
          </Typography>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              value={formData.date_of_birth}
              onChange={handleDateChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={!!formErrors.date_of_birth}
                  helperText={formErrors.date_of_birth}
                />
              )}
            />
          </LocalizationProvider>

           {/* Display Addresses in Edit Mode as Cards */}
              {modalState.mode === 'edit' && selectedCustomer.addresses.length > 0 && (
                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Addresses
                  </Typography>
                  <Grid container spacing={2}>
                    {selectedCustomer.addresses.map((address) => (
                      <Grid item xs={12} sm={12} key={address.id}>
                        <Card variant="outlined">
                          <CardContent>
                            <Typography variant="subtitle1" component="div" gutterBottom>
                              {address.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Latitude:</strong> {address.latitude}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Longitude:</strong> {address.longitude}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Zone:</strong> {address.zone}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Subzone:</strong> {address.subzone}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Appartment / Vila Details:</strong> {address.appartment_details || "-"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Buiding Details:</strong> {address.building_details || "-"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Street Details:</strong> {address.street_details || "-"}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              <strong>Landmark:</strong> {address.landmark_details || "-"}
                            </Typography>
                            {/* <Typography variant="body2" color="textSecondary">
                              <strong>details:</strong> {address.full_address}
                            </Typography> */}
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}


        </FormControl>

      </DialogContent>

      <DialogActions>
         <Button onClick={handleClose}>
           Cancel
         </Button>
         {modalState.mode === 'edit' ? (
           <Button onClick={handleEdit} color="primary" variant="contained">
             Update
           </Button>
         ) : (
           <Button onClick={handleAdd} color="primary" variant="contained">
             Add
           </Button>
         )}
       </DialogActions>
    </BootstrapDialog>
  );
};

export default CustomerFormDialog;
