import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    MenuItem,
    Grid,
    TextField,
    Typography,
    Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { AUDIENCE_GET_ENDPOINT } from '../../../config/urls';
import { useAuth } from '../../../hooks/AuthProvider';

const SelectSegment = ({
        campaignName, setCampaignName,
        campaignDescription, setCampaignDescription,
        selectedSegment, setSelectedSegment,
        selectedSegmentList, setSelectedSegmentList,
        phoneNumberList, setPhoneNumberList,
        campaignsData
    }) => {

    const { getValidToken } = useAuth();
    
    const [audienceData, setAudienceData] = useState([]);

    const [activeSegments, setActiveSegments] = useState([]);

    const [error, setError] = useState(null);

    console.log("selectedSegmentList: ", selectedSegmentList);
    console.log("phoneNumberList: ", phoneNumberList);

    const handleSegmentChange = (event) => {
        const selectedValue = event.target.value;
        // Prevent adding already selected segments
        if (selectedValue !== "Select a segment" && 
            !selectedSegmentList.includes(selectedValue)) {
            // Update selectedSegmentList with new segment
            setSelectedSegmentList([...selectedSegmentList, selectedValue]);

            let numberList = audienceData.reduce((acc, customer) => {
                if (customer.segments.includes(selectedValue)) {
                    acc.push(`${customer.country_code}${customer.phone_no}`);
                }
                return acc;
            }, []);
            
            setPhoneNumberList(prevList => [...prevList, ...numberList]);
        }
        
        // Reset segment selection
        setSelectedSegment("Select a segment");
    };

    // Function to handle segment deletion
    const handleSegmentDelete = (segmentToRemove) => {
        // Remove segment from selectedSegmentList
        const updatedSegmentList = selectedSegmentList.filter(
            segment => segment !== segmentToRemove
        );
        setSelectedSegmentList(updatedSegmentList);

        // Remove corresponding phone numbers
        const numbersToRemove = audienceData.reduce((acc, customer) => {
            if (customer.segments.includes(segmentToRemove)) {
                acc.push(`${customer.country_code}${customer.phone_no}`);
            }
            return acc;
        }, []);

        // Remove specific phone numbers from phoneNumberList
        setPhoneNumberList(prevList => 
            prevList.filter(number => !numbersToRemove.includes(number))
        );
    };

    const handleNameChange = (event) => {
        const newName = event.target.value;
        
        if (newName === '' || newName.trim().length > 0) {
            if (newName.length <= 50) {
                setCampaignName(newName);
            }
        }
    };
    
    const handleDescriptionChange = (event) => {
        const newDescription = event.target.value;
        
        if (newDescription === '' || newDescription.trim().length > 0) {
            if (newDescription.length <= 250) {
                setCampaignDescription(newDescription);
            }
        }
    };
    
    const fetchData = async () => {

        const token = await getValidToken();

        try {
            const response = await fetch(AUDIENCE_GET_ENDPOINT + `?limit=1000`, {
                headers: {
                    'Authorization': `Bearer ${token}`
            }});
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const jsonData = await response.json();
        
            console.log("GET API response: ", jsonData);
        
            setAudienceData(jsonData.results);
        
            setActiveSegments(jsonData.all_segments_details.filter(segment => segment.active === true).map(segment => segment.segment_name));
        
        } catch (error) {
            console.error('Error fetching data:', error.message);
        }
    };

    useEffect(() => {
        const isDuplicate = campaignsData.some(campaign => campaign.toLowerCase() === campaignName.toLowerCase());
        setError(isDuplicate);
        fetchData();
    }, [
        selectedSegment,
        phoneNumberList,
        campaignName,
        campaignDescription,
        selectedSegmentList
    ]);
    
    return (
        <Grid container spacing={2} alignItems="center" sx={{mt: 1}}>
            {/* Campaign Name */}
            <Grid item xs={12}>
                <div style={{ position: 'relative', width: '90%', marginTop: 2, marginBottom: 2 }}>
                    
                    <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                        Campaign Name *
                    </Typography>
                    <TextField
                        label="Campaign Name"
                        value={campaignName}
                        onChange={handleNameChange}
                        fullWidth
                        error={error}
                        helperText={error ? "Campaign name must be unique." : ""}
                        required
                    />
                    <Typography 
                        variant="body2" 
                        sx={{ 
                            color: 'gray', 
                            position: 'absolute', 
                            right: '10px', 
                            top: '10px',
                            pointerEvents: 'none'
                        }}>
                        {`${campaignName.length}/50`}
                    </Typography>
                    {error && <Typography color="error">{error}</Typography>}
                </div>
            </Grid>

            {/* Campaign Description */}
            <Grid item xs={12}>
                <div style={{ position: 'relative', width: '90%', marginTop: 2, marginBottom: 2 }}>
                    
                    <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                        Campaign Description
                    </Typography>
                    <TextField
                        label="Campaign Description"
                        multiline
                        rows={4}
                        value={campaignDescription}
                        onChange={handleDescriptionChange}
                        fullWidth
                    />
                    <Typography 
                        variant="body2"
                        sx={{ 
                            color: 'gray',
                            position: 'absolute',
                            right: '10px',
                            top: '10px',
                            pointerEvents: 'none'
                        }}>
                        {`${campaignDescription.length}/250`}
                    </Typography>
                </div>
            </Grid>
            
            {/* Segment Drop Down */}
            <Grid item xs={6}>
                <div>
                    <Typography variant="h6" sx={{ marginBottom: 1, mt: 1 }}>
                        Select Segment *
                    </Typography>
                    <Select 
                        value={selectedSegment} 
                        onChange={handleSegmentChange}
                        fullWidth
                        sx={{ mt: 1, mb: 2 }}
                    >
                        <MenuItem value="Select a segment">
                            Select a segment
                        </MenuItem>
                        {/* Filter out already selected segments */}
                        {activeSegments
                            .filter(segment => !selectedSegmentList.includes(segment))
                            .map((segment, index) => (
                            <MenuItem 
                                key={index} 
                                value={segment}
                            >
                                {segment}
                            </MenuItem>
                        ))}
                    </Select>

                    {/* Display selected segments with delete option */}
                    <Grid container spacing={1} sx={{ mt: 1 }}>
                        {selectedSegmentList.map((segment, index) => (
                            <Grid item key={index}>
                                <Chip
                                    label={segment}
                                    onDelete={() => handleSegmentDelete(segment)}
                                    deleteIcon={<CloseIcon />}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};
export default SelectSegment;