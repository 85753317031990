import React, { useState, useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import Chip from '@mui/material/Chip';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';
import { MenuItem } from '@mui/material';
import { Select } from '@mui/material';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Alert } from '@mui/material';

import dayjs from 'dayjs';
import { CAMPAIGN_LIST_ENDPOINT, AUDIENCE_GET_ENDPOINT } from '../../config/urls';
import { useAuth } from '../../hooks/AuthProvider';

const columns = [
  { id: 'campaign_name', label: 'Campaign Name' },
  { id: 'created_by', label: 'Initiated By' },
  { id: 'created_at', label: 'Created At' },
  { id: 'status', label: 'Status' },
  { id: 'segments', label: 'Segments' },
  { id: 'template', label: 'Template' },
  { id: 'campaign_description', label: 'Campaign Description' },
];

export default function CampaignsTable({ refreshKey, dateInput }) {
  const { getValidToken } = useAuth();
  const [rowCount, setRowCount] = useState(0);
  
  const [campaignsData, setCampaignsData] = useState([]);
  const [allSegments, setAllSegments] = useState([]);

  const URL = process.env.NODE_ENV === 'production' ? 'api' : `http://localhost:${process.env.REACT_APP_DOMAIN_BACKEND_PORT}/api`;
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 20
  });

  const segmentOptions = [...new Set(allSegments)].map(segment => (
    <MenuItem key={segment} value={segment}>{segment}</MenuItem>
  ));

  const [searchQuery, setSearchQuery] = useState('');
  const [segmentFilter, setSegmentFilter] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [dateError, setDateError] = useState(null);


  const fetchCampaignsData = async () => {
    let searchDateURL = '';
    if (dateInput) {
      let dateString = dayjs(dateInput).format("YYYY-MM-DD");
      searchDateURL = `&search_date=${dateString}`;
    }

    // Construct query parameters for server-side filtering and pagination
    const queryParams = new URLSearchParams({
      limit: controller.rowsPerPage.toString(),
      offset: (controller.serverSidePage * controller.rowsPerPage).toString(),
      search_query: searchQuery,
      segments_filter: segmentFilter,
      from_date: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : '',
      to_date: toDate ? dayjs(toDate).format("YYYY-MM-DD") : ''
    });

    const token = await getValidToken();

    try {
      const response = await fetch(`${CAMPAIGN_LIST_ENDPOINT}?${queryParams}${searchDateURL}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch campaigns data');
      }
      const jsonData = await response.json();

      console.log("GET Campaigns Data: ", jsonData);

      setCampaignsData(jsonData.results);
      setRowCount(jsonData.count);
    } catch (error) {
      console.error('Error fetching campaigns data:', error.message);
    }
  };

  const fetchSegmentsData = async () => {

    const token = await getValidToken();

    try {
      const response = await fetch(AUDIENCE_GET_ENDPOINT + `?limit=1000`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch segments data');
      }
      const jsonData = await response.json();

      console.log("GET Segments Data: ", jsonData);

      setAllSegments(jsonData.all_segments_details.map(segment => segment.segment_name));
    } catch (error) {
      console.error('Error fetching segments data:', error.message);
    }
  };
  
  useEffect(() => {
    fetchCampaignsData();
    fetchSegmentsData();
  }, [
    controller.serverSidePage, 
    controller.rowsPerPage, 
    refreshKey, 
    dateInput, 
    searchQuery, 
    segmentFilter,
    fromDate, 
    toDate
  ]);

  // pagination
  const handlePageChange = (event, newPage) => {
    setController(prev => ({
      ...prev,
      page: newPage,
      serverSidePage: newPage  // Sync server-side page with UI page
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...prev => ({
        ...prev,
        rowsPerPage: parseInt(event.target.value, 10),
        page: 0,
        serverSidePage: 0  // Reset to first page
      })
    });
  };

  const handleSearchQueryChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSegmentFilterChange = (event) => {
    setSegmentFilter(event.target.value);
  };

  const handleFromDateChange = (newValue) => {
    if (toDate && newValue && dayjs(newValue).isAfter(toDate)) {
      setDateError('From Date must be less than or equal to To Date');
    } else {
      setDateError(null);
      setFromDate(newValue);
    }
  };

  const handleToDateChange = (newValue) => {
    if (fromDate && newValue && dayjs(newValue).isBefore(fromDate)) {
      setDateError('To Date must be greater than or equal to From Date');
    } else {
      setDateError(null);
      setToDate(newValue);
    }
  };

  const handleResetDateFilter = () => {
    setFromDate(null);
    setToDate(null);
    setDateError(null);
  };

  const filteredCampaigns  = campaignsData.filter((campaign) => {
    const searchQueryMatch = campaign.campaign_name.toLowerCase().includes(searchQuery.toLowerCase());
    const segmentFilterMatch = segmentFilter === '' || campaign.segment === segmentFilter;
    const dateFilterMatch = (fromDate === null && toDate === null) || 
        (fromDate === null ? true : new Date(campaign.created_at) >= fromDate) &&
        (toDate === null ? true : new Date(campaign.created_at) <= toDate);

    return searchQueryMatch && segmentFilterMatch && dateFilterMatch;
  });

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
          }}
        >
            <Grid container spacing={1}>
                <Grid item xs={2}>
                <TextField
                    label="Search"
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                />
                </Grid>
                <Grid item xs={2}>
                <Select
                    value={segmentFilter}
                    onChange={handleSegmentFilterChange}
                    displayEmpty
                    fullWidth
                >
                    <MenuItem value="">Segments</MenuItem>
                    {segmentOptions}
                </Select>
                </Grid>
                <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                    label="From Date"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
                    <DatePicker
                    label="To Date"
                    value={toDate}
                    onChange={handleToDateChange}
                    renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={2}>
                <Button variant="contained" onClick={handleResetDateFilter} disabled={fromDate === null && toDate === null}>Reset Date Filter</Button>
                </Grid>
            </Grid>
            {dateError && (
                <Alert 
                    severity="error" 
                    sx={{ 
                    width: '100%', 
                    marginTop: 1 
                    }}
                >
                    {dateError}
                </Alert>
            )}

        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align="left"
                    style={{ minWidth: column.minWidth, border: '1px solid rgba(224, 224, 224, 1)' }}
                  >
                    <h3>{column.label}</h3>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {campaignsData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No match found
                  </TableCell>
                </TableRow>
              ) : (
                campaignsData.map((campaign, index) => (
                  <TableRow key={index}>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{campaign.campaign_name}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{campaign.created_by}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{campaign.created_at}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                      {campaign.status ? 
                        <Chip variant="outlined" color="success" label="Complete" size="small" /> :
                        <Chip variant="outlined" color="warning" label="Failed" size="small" />
                      }
                    </TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{campaign.segments.join(', ')}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{campaign.template}</TableCell>
                    <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{campaign.campaign_description}</TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20]}
          component="div"
          count={rowCount}
          rowsPerPage={controller.rowsPerPage}
          page={controller.page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
