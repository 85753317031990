import React, { useState, useEffect, useRef } from 'react';
import {
    Select,
    MenuItem,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Typography,
} from '@mui/material';

const SingleContact = (props) => {
    const setPhoneNumber = props.setPhoneNumber;

    const [countryCode, setCountryCode] = useState('');
    const [phoneNumberSuffix, setPhoneNumberSuffix] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const handlePhoneNumberChange = (e) => {
        // Only allow numbers
        const value = e.target.value.replace(/\D/g, '');
        
        setPhoneNumberSuffix(value);

        // Validate phone number length
        if (value.length !== 10) {
            setPhoneError('Phone number must be exactly 10 digits');
        } else {
            setPhoneError('');
        }
    }
    
    const handleConcatenation = () => {
        if (countryCode && phoneNumberSuffix && phoneNumberSuffix.length === 10) {
            setPhoneNumber(`${countryCode}${phoneNumberSuffix}`);
        } else {
            setPhoneNumber('');
        }
    }

    useEffect(() => {
        handleConcatenation();
    }, [countryCode, phoneNumberSuffix]);
    
    return (
        <Grid container spacing={2} alignItems="center" sx={{mt: 1}}>
            <Grid item xs={3}>
                <Typography variant="h6" sx={{ mt: 1, mb: 1 }} gutterBottom>
                    Country Code *
                </Typography>
                <FormControl fullWidth>
                    <InputLabel>Country Code *</InputLabel>
                    <Select
                        label="Country Code"
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        fullWidth
                        required
                    >
                        <MenuItem value="">Select Country Code</MenuItem>
                        <MenuItem value="91">IND (+91)</MenuItem>
                        <MenuItem value="1">US (+1)</MenuItem>
                        <MenuItem value="971">UAE (+971)</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <Typography variant="h6" sx={{ mt: 1, mb: 1 }} gutterBottom>
                    Phone Number *
                </Typography>
                <TextField
                    sx={{ width: '80%' }}
                    id="outlined-basic"
                    label="Phone Number"
                    variant="outlined"
                    value={phoneNumberSuffix}
                    // onChange={(e) => setPhoneNumberSuffix(e.target.value)}
                    onChange={handlePhoneNumberChange}
                    required
                />
            </Grid>
            {phoneError && (
                <Typography 
                    color="error" 
                    variant="caption" 
                    sx={{ 
                        display: 'block', 
                        mt: 1, 
                        ml: 1 
                    }}
                >
                    {phoneError}
                </Typography>
            )}
        </Grid>
    );
};
export default SingleContact;